import {useState, useEffect, useMemo} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import {useThemeMode} from '../../_metronic/partials'
import {PageTitle} from '../../_metronic/layout/core'
import usePostApi from './custom_hooks/usePostApi'
import {KTIcon} from '../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Modal} from 'react-bootstrap'
import {useIntl, FormattedMessage} from 'react-intl'
import AddNewAttachment from './media/components/image/AddNewAttachment'
import UploadVideo from './media/components/video/UploadVideo'
import UploadAudio from './media/components/audio/UploadAudio'
import AudioIcon from './media/components/audio/AudioFile.png'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {UseToast} from './useToast'
import GallerySelectModal from './helpers/GallerySelectModal'
import '../modules/media/components/index.css'
import Carousel from 'react-bootstrap/Carousel'
import {CreateCategory} from '../pages/Category/CreateCategory'
import {CreateSubCategory} from '../pages/Category/CreateSubCategory'

function CreateArticleFullPage({returnPath, secId}: any) {
  const {type, article_id, id} = useParams()
  const navigate = useNavigate()
  const {mode} = useThemeMode()
  const {execute, postLoading} = usePostApi()
  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState({
    title_ar: '',
    title_ar_phonetic: '',
    title_en: '',
    title_en_phonetic: '',
    section_id: secId,
    category_id: '',
    sub_category_id: '',
    content_ar: '',
    content_ar_phonetic: '',
    content_en: '',
    content_en_phonetic: '',
    media_type: 'I',
    media_id: '',
    voice_ar_type: 'TTS',
    voice_ar: '',
    voice_en_type: 'TTS',
    voice_en: '',
    status: true,
    start_ts: new Date(),
    end_ts: '',
    tags_ar: '',
    tags_en: '',
    media_title_en: '',
    media_title_ar: '',
    media_url: '',
    sort_rank: 100,
  })

  const [initArtData, setInitArtData]: any = useState({})

  const getInitData = async () => {
    try {
      const url = `/articles/get`
      const formData = {article_id: article_id}
      const response = await execute(url, 'POST', formData)
      setInitArtData(response.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    if (type === 'create') {
      setIsLoading(false)
    }
    if (type === 'edit' && article_id) {
      getInitData()
    }
  }, [type])

  useEffect(() => {
    if (type === 'edit' && initArtData && Object.keys(initArtData)?.length > 0) {
      setData({
        ...initArtData,
        media_url: initArtData?.media_location_url,
        media_type: initArtData.media_type === 'P' ? 'I' : initArtData.media_type,
      })
    }
  }, [initArtData])

  const validationSchema = Yup.object().shape({
    title_ar: Yup.string().required('Title (Arabic) is required'),
    title_ar_phonetic: Yup.string().required('Title phonetic (Arabic) is required'),
    section_id: Yup.string().required('Section is required'),
    category_id: Yup.string().required('Category is required'),
    media_id: Yup.string().required('Media is required'),
    media_type: Yup.string().required('Media type is required'),
    content_ar: Yup.string().required('Content (Arabic) is required'),
    content_ar_phonetic: Yup.string().required('Content phonetic (Arabic) is required'),
    voice_ar_type: Yup.string().required('Voice type (Arabic) is required'),
    start_ts: Yup.string().required('Start Date is required'),
    end_ts: Yup.string()
      .nullable()
      .test('is-greater', 'End Date must be greater than Start Date', function (value) {
        const {start_ts} = this.parent
        if (!value) {
          return true
        }
        return start_ts ? new Date(value) > new Date(start_ts) : true
      }),

    tags_ar: Yup.string().required('Tags (Arabic) is required'),
    voice_ar: Yup.string().when('voice_ar_type', {
      is: (voice_ar_type: string) => voice_ar_type === 'REC',
      then: (schema) => schema.required('Record (Ar) is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    voice_en: Yup.string().when('voice_en_type', {
      is: (voice_en_type: string) => voice_en_type === 'REC',
      then: (schema) => schema.required('Record (En) is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    sort_rank: Yup.number().min(0, 'Value must be at least 0'),
  })

  const candidateAttachments: any = []

  const [sections, setSections] = useState([])
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(secId)

  const fetchSections = async () => {
    try {
      const url = `/section/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setSections(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchSections()
  }, [])

  const [categories, setCategories] = useState([])
  const fetchCategory = async (id: any) => {
    try {
      const url = `/category/section_id/get`
      const formData = {section_id: id}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.category_id,
        label: `${each.category_name_ar}${
          each.category_name_en ? ` - ${each.category_name_en}` : ''
        }`,
        media_id: each.media_id,
      }))
      setCategories(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    if (selectedSectionId) {
      fetchCategory(selectedSectionId)
    }
  }, [selectedSectionId])

  const [subCategories, setSubCategories] = useState([])

  const fetchSubCategory = async (id: any) => {
    try {
      const url = `/subcategory/get/category_id`
      const formData = {category_id: id}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.sub_category_id,
        label: `${each.sub_category_name_ar}${
          each.sub_category_name_en ? ` - ${each.sub_category_name_en}` : ''
        }`,
      }))
      setSubCategories(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null | string>(null)
  useEffect(() => {
    if (data?.category_id) {
      setSelectedCategoryId(data.category_id)
    }
  }, [data.category_id])

  const [categoryMediaId, setCategoryMediaId] = useState<number | null>(null)

  const handleCategoryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('category_id', selectedOption.value)
      setFieldValue('sub_category_id', null)
      setSubCategories([])
      setSelectedCategoryId(selectedOption.value)
      setCategoryMediaId(selectedOption.media_id)
    } else {
      setFieldValue('category_id', '')
      setSelectedCategoryId(null)
      setCategoryMediaId(null)
    }
  }

  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategory(selectedCategoryId)
    }
  }, [selectedCategoryId])

  const fetchSubCategoryWithCurrCategory = () => {
    if (selectedCategoryId) {
      fetchSubCategory(selectedCategoryId)
    } else return
  }

  const fetchCategoryWithCurrCategory = () => {
    if (selectedSectionId) {
      fetchCategory(selectedSectionId)
    } else return
  }

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)
  const handlesubcategoryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('sub_category_id', selectedOption.value)
    } else {
      setFieldValue('sub_category_id', '')
    }
  }

  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false)

  const [showImageModal, setShowImageModal] = useState(false)

  const handleImageSelectFunc = (img: any, setFieldValue: any) => {
    setFieldValue('media_id', img.media_id)
    setFieldValue('media_title_en', img.media_title_en)
    setFieldValue('media_title_ar', img.media_title_ar)
    setFieldValue('media_url', img.media_location_url)
    setShowImageModal(false)
  }

  const [showAudioModal, setShowAudioModal] = useState(false)
  const handleAudioSelectFunc = (audio: any, setFieldValue: any) => {
    setFieldValue('media_id', audio.media_id)
    setFieldValue('media_title_en', audio.media_title_en)
    setFieldValue('media_title_ar', audio.media_title_ar)
    setFieldValue('media_url', audio.media_location_url)
    setShowAudioModal(false)
  }

  const [showVidModal, setShowVidModal] = useState(false)
  const handleVidSelectFunc = (video: any, setFieldValue: any) => {
    setFieldValue('media_id', video.media_id)
    setFieldValue('media_title_en', video.media_title_en)
    setFieldValue('media_title_ar', video.media_title_ar)
    setFieldValue('media_url', video.media_location_url)
    setShowVidModal(false)
  }

  const handleGallerySelectFunc = (img: any, setFieldValue: any) => {
    setFieldValue('media_id', img.media_id)
    setFieldValue('media_title_en', img.media_title_en)
    setFieldValue('media_title_ar', img.media_title_ar)
    setFieldValue('media_url', img.metadata)
    setShowImageModal(false)
  }

  function formatDateForDB(dateString: string) {
    if (!dateString) {
      return ''
    }

    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return ''
    }

    const pad = (num: number) => (num < 10 ? `0${num}` : num)
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    const milliseconds = String(date.getMilliseconds()).padStart(6, '0')

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

    return formattedDate
  }

  const onSubmit = async (values: any) => {
    try {
      const url = type === 'edit' ? '/articles/update' : `/articles/create`
      const formData = {
        article_id: type === 'edit' ? article_id : undefined,
        title_ar: values.title_ar,
        title_ar_phonetic: values.title_ar_phonetic,
        title_en: values.title_en || '',
        title_en_phonetic: values.title_en_phonetic || '',
        section_id: values.section_id,
        category_id: values.category_id,
        sub_category_id: values.sub_category_id || null,
        content_ar: values.content_ar,
        content_ar_phonetic: values.content_ar_phonetic,
        content_en: values.content_en || '',
        content_en_phonetic: values.content_en_phonetic || '',
        media_type: values.media_type === 'I' ? 'P' : values.media_type,
        media_id: values.media_id,
        status: data.status ? 1 : 0,
        voice_ar_type: values.voice_ar_type,
        voice_ar: values.voice_ar_type === 'REC' ? values.voice_ar : '',
        voice_en_type: values.voice_en_type,
        voice_en: values.voice_en_type === 'REC' ? values.voice_en : '',
        start_ts: formatDateForDB(values.start_ts),
        end_ts: values.end_ts ? formatDateForDB(values.end_ts) : '',
        tags_en: values.tags_en || '',
        tags_ar: values.tags_ar,
        sort_rank: values.sort_rank === '' ? 100 : values.sort_rank,
      }

      const response = await execute(url, 'POST', formData, 'post')
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
      if (type === 'create') {
        navigate(`${returnPath}/${response.data.article_id}`)
      } else {
        navigate(`${returnPath}/${article_id}`)
      }
      // return navigate(`/${id}/communities/manage-topics/${article_id}`)
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const [audiosData, setAudiosData] = useState([])

  const getAudiosData = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'A',
      }
      const response = await execute(url, 'POST', formData)
      setAudiosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getAudiosData()
  }, [])

  const [galleryData, setGalleryData] = useState([])
  const fetchGalleryItems = async () => {
    try {
      const url = `/media/get/all`
      const formData = {media_type: 'G'}
      const response = await execute(url, 'POST', formData)
      setGalleryData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchGalleryItems()
  }, [])

  const [showAudioSelectModal, setShowAudioSelectModal] = useState(false)
  const [selectedAudio, setSelectedAudio]: any = useState({})
  useEffect(() => {
    if (data.voice_ar && audiosData?.length > 0) {
      const audio = audiosData.find((each: any) => each.media_id === data.voice_ar)
      setSelectedAudio(audio)
    }
  }, [data.voice_ar, audiosData])

  const [showAudioSelectModalEn, setShowAudioSelectModalEn] = useState(false)
  const [selectedAudioEn, setSelectedAudioEn]: any = useState({})

  useEffect(() => {
    if (data.voice_en && audiosData?.length > 0) {
      const audio = audiosData.find((each: any) => each.media_id === data.voice_en)
      setSelectedAudioEn(audio)
    }
  }, [data.voice_en, audiosData])

  const handleAudioSelect = (audio: any, setFieldValue: any) => {
    setFieldValue('voice_ar', audio.media_id)
    setSelectedAudio(audio)
    setShowAudioSelectModal(false)
  }

  const handleAudioSelectEn = (audio: any, setFieldValue: any) => {
    setFieldValue('voice_en', audio.media_id)
    setSelectedAudioEn(audio)
    setShowAudioSelectModalEn(false)
  }

  const [showGalleryModal, setShowGalleryModal] = useState(false)

  if (isLoading) {
    return (
      <div className='card h-250px'>
        <div className='m-auto d-flex flex-column align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      </div>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={candidateAttachments}>Create New Article</PageTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={data}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, values, handleChange}: any) => {
          return (
            <Form className='w-100'>
              <CreateCategory
                className='modal-backdrop'
                showModal={showAddCategoryModal}
                setShowModal={setShowAddCategoryModal}
                id={{sectionId: secId}}
                categoryFetchData={fetchCategoryWithCurrCategory}
                closeModal={() => setShowAddCategoryModal(false)}
              />

              <CreateSubCategory
                className='modal-backdrop'
                showModal={showAddSubCategoryModal}
                setShowModal={setShowAddSubCategoryModal}
                subCategoryFetchData={fetchSubCategoryWithCurrCategory}
                id={{media_id: categoryMediaId, category_id: selectedCategoryId}}
              />

              <ImageSelectModal
                showImgSelectModal={showImageModal}
                setShowImgSelectModal={setShowImageModal}
                handleImageSelect={handleImageSelectFunc}
                setFieldValue={setFieldValue}
              />

              <AudioSelectModal
                showAudioSelectModal={showAudioModal}
                setShowAudioSelectModal={setShowAudioModal}
                handleAudioSelect={handleAudioSelectFunc}
                setFieldValue={setFieldValue}
                audiosData={audiosData}
                getMediatype={getAudiosData}
              />

              <VideoSelectModal
                showVidSelectModal={showVidModal}
                setShowVidSelectModal={setShowVidModal}
                handleVidSelect={handleVidSelectFunc}
                setFieldValue={setFieldValue}
              />

              <AudioSelectModal
                showAudioSelectModal={showAudioSelectModal}
                setShowAudioSelectModal={setShowAudioSelectModal}
                handleAudioSelect={handleAudioSelect}
                setFieldValue={setFieldValue}
                audiosData={audiosData}
                getMediatype={getAudiosData}
              />
              <AudioSelectModal
                showAudioSelectModal={showAudioSelectModalEn}
                setShowAudioSelectModal={setShowAudioSelectModalEn}
                handleAudioSelect={handleAudioSelectEn}
                setFieldValue={setFieldValue}
                audiosData={audiosData}
                getMediatype={getAudiosData}
              />

              <GallerySelectModal
                showModal={showGalleryModal}
                setShowModal={setShowGalleryModal}
                galleryData={galleryData}
                fetchGalleryItems={fetchGalleryItems}
                handleGallerySelect={(img: any) => handleGallerySelectFunc(img, setFieldValue)}
              />

              <div className='d-flex justify-content-between align-items-center mb-5'>
                <h3 className='fw-normal'>
                  {values.title_en} - {values.title_ar}
                </h3>
                <div>
                  <Link to={returnPath} className='btn btn-light-primary btn-sm'>
                    <KTIcon iconName='arrow-left' className='fs-2' />
                    Back
                  </Link>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm ms-4'
                    disabled={postLoading}
                    data-toggle='tooltip'
                    title={type === 'create' ? 'Create article.' : 'Update article.'}
                  >
                    {postLoading ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <> {type === 'create' ? 'Create' : 'Update'}</>
                    )}
                  </button>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row'>
                <div className='w-100 w-md-75 me-md-3 card p-5'>
                  <div className='d-flex flex-column flex-md-row mb-5'>
                    <div className='w-100 w-md-50 me-md-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Title (Arabic)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='title_ar'
                        className='form-control form-control-solid'
                        placeholder='أدخل العنوان'
                        dir='rtl'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('title_ar', value)

                          if (
                            !values.title_ar_phonetic ||
                            values.title_ar_phonetic === values.title_ar
                          ) {
                            setFieldValue('title_ar_phonetic', value)
                          }
                        }}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='title_ar' />
                      </div>
                    </div>

                    <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Title (English)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='title_en'
                        className='form-control form-control-solid'
                        placeholder='Enter title'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('title_en', value)

                          if (
                            !values.title_en_phonetic ||
                            values.title_en_phonetic === values.title_en
                          ) {
                            setFieldValue('title_en_phonetic', value)
                          }
                        }}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='title_en' />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-column flex-md-row mb-5'>
                    <div className='w-100 w-md-50 me-md-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Title Phonetic (Arabic)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='title_ar_phonetic'
                        className='form-control form-control-solid'
                        placeholder='أدخل العنوان'
                        dir='rtl'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('title_ar_phonetic', value)
                        }}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='title_ar_phonetic' />
                      </div>
                    </div>

                    <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Title Phonetic (English)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='title_en_phonetic'
                        className='form-control form-control-solid'
                        placeholder='Enter title phonetic'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('title_en_phonetic', value)
                        }}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='title_en_phonetic' />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-column flex-md-row mb-5'>
                    <div className='w-100 w-md-50 me-md-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Content (Arabic)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        as='textarea'
                        type='text'
                        name='content_ar'
                        className='form-control form-control-solid'
                        placeholder='أدخل المحتوى (عربي)'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('content_ar', value)
                          if (
                            !values.content_ar_phonetic ||
                            values.content_ar_phonetic === values.content_ar
                          ) {
                            setFieldValue('content_ar_phonetic', value)
                          }
                        }}
                        dir='rtl'
                        rows={10}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='content_ar' />
                      </div>
                    </div>

                    <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Content (English)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        as='textarea'
                        type='text'
                        name='content_en'
                        className='form-control form-control-solid'
                        placeholder='Enter content (English)'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('content_en', value)
                          if (
                            !values.content_en_phonetic ||
                            values.content_en_phonetic === values.content_en
                          ) {
                            setFieldValue('content_en_phonetic', value)
                          }
                        }}
                        rows={10}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='content_en' />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-column flex-md-row mb-5'>
                    <div className='w-100 w-md-50 me-md-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Content Phonetic (Arabic)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        as='textarea'
                        type='text'
                        name='content_ar_phonetic'
                        className='form-control form-control-solid'
                        placeholder='أدخل المحتوى (عربي)'
                        dir='rtl'
                        rows={10}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='content_ar_phonetic' />
                      </div>
                    </div>

                    <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Content Phonetic (English)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter the title of the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        as='textarea'
                        type='text'
                        name='content_en_phonetic'
                        className='form-control form-control-solid'
                        placeholder='Enter content phonetic (English)'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const {value} = e.target
                          setFieldValue('content_en_phonetic', value)
                        }}
                        rows={10}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='content_en_phonetic' />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-column flex-md-row mb-5'>
                    <div className='w-100 w-md-50 me-md-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className=''>Tags (Arabic)</span>
                        <i
                          className='fas fa-info-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter relevant tags or keywords for the article in Arabic.'
                        ></i>
                      </label>
                      <Field
                        id='tags'
                        type='text'
                        name='tags_ar'
                        className='form-control form-control-solid'
                        placeholder='أدخل العلامات مفصولة بفواصل'
                        dir='rtl'
                        onChange={(e: any) => {
                          setFieldValue('tags_ar', e.target.value)
                        }}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='tags_ar' />
                      </div>
                    </div>

                    <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className=''>Tags (English)</span>
                        <i
                          className='fas fa-info-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter relevant tags or keywords for the article in English.'
                        ></i>
                      </label>
                      <Field
                        id='tags'
                        type='text'
                        name='tags_en'
                        className='form-control form-control-solid'
                        placeholder="Enter tags separated by comma's"
                        onChange={(e: any) => {
                          setFieldValue('tags_en', e.target.value)
                        }}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='tags_en' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-100 w-md-25 card ms-md-3 p-5'>
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Media Type</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose the type of media you want to associate with this article: audio, video, or image.'
                      ></i>
                    </label>

                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='image_type'
                        name='media_type'
                        value='A'
                        checked={values.media_type === 'A'}
                        onChange={(event) => {
                          handleChange(event) // This updates the form state
                          setFieldValue('media_type', 'A')
                          setFieldValue('media_id', null)
                          setFieldValue('media_title_en', null)
                          setFieldValue('media_title_ar', null)
                          setFieldValue('media_url', null)
                        }}
                      />
                      <label className='form-check-label fs-6' htmlFor='image_type'>
                        Audio
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='video_type'
                        name='media_type'
                        value='V'
                        checked={values.media_type === 'V'} // Check if the value is 'F' (Female)
                        onChange={(event) => {
                          handleChange(event) // This updates the form state
                          setFieldValue('media_type', 'V')
                          setFieldValue('media_id', null)
                          setFieldValue('media_title_en', null)
                          setFieldValue('media_title_ar', null)
                          setFieldValue('media_url', null)
                        }}
                      />
                      <label className='form-check-label fs-6' htmlFor='video_type'>
                        Video
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='Images_type'
                        name='media_type'
                        value='I'
                        checked={values.media_type === 'I'}
                        onChange={(event) => {
                          handleChange(event)
                          setFieldValue('media_type', 'I')
                          setFieldValue('media_id', null)
                          setFieldValue('media_title_en', null)
                          setFieldValue('media_title_ar', null)
                          setFieldValue('media_url', null)
                        }}
                      />
                      <label className='form-check-label fs-6' htmlFor='Images_type'>
                        Images
                      </label>
                    </div>

                    <div className='form-check form-check-inline pt-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='Galley'
                        name='media_type'
                        value='G'
                        checked={values.media_type === 'G'}
                        onChange={(event) => {
                          handleChange(event)
                          setFieldValue('media_type', 'G')
                          setFieldValue('media_id', null)
                          setFieldValue('media_title_en', null)
                          setFieldValue('media_title_ar', null)
                          setFieldValue('media_url', null)
                        }}
                      />
                      <label className='form-check-label fs-6' htmlFor='Galley'>
                        Gallery/Slider
                      </label>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='media_type' />
                    </div>
                  </div>

                  {values.media_type === 'I' && (
                    <div className='w-100 mb-5'>
                      <label className='fs-6 fw-semibold form-label mb-2 required'>
                        Upload Photo
                      </label>
                      <div className='d-flex align-items-center'>
                        {values.media_url ? (
                          <img
                            src={values.media_url}
                            alt={values?.media_title_en || values.media_title_ar}
                            className='rounded me-5 cursor-pointer'
                            style={{width: '180px', height: '120px'}}
                            onClick={() => setShowImageModal(true)}
                          />
                        ) : (
                          <div
                            className='border border-3 border-secondary border-dashed d-flex align-items-center justify-content-center me-5 cursor-pointer rounded'
                            style={{width: '80px', height: '80px'}}
                            onClick={() => setShowImageModal(true)}
                          >
                            <KTIcon iconName='user' className='fs-2' />
                          </div>
                        )}
                        <button
                          type='button'
                          onClick={() => setShowImageModal(true)}
                          className='btn btn-light-primary btn-sm'
                        >
                          Select Image
                        </button>
                      </div>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='media_id' />
                      </div>
                    </div>
                  )}

                  {values.media_type === 'A' && (
                    <div className='mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Upload Audio</span>
                      </label>

                      <div className='d-flex align-items-center'>
                        <button
                          type='button'
                          className='btn btn-light-primary btn-sm me-2'
                          onClick={() => setShowAudioModal(true)}
                        >
                          Select Audio
                        </button>
                        {values.media_id && values.media_url && (
                          <audio src={values.media_url} controls />
                        )}
                      </div>
                      <ErrorMessage name='media_id' component='div' className='text-danger mt-2' />
                    </div>
                  )}

                  {values.media_type === 'V' && (
                    <div className='mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Upload Video</span>
                      </label>

                      <div className='d-flex flex-column align-items-start'>
                        <button
                          type='button'
                          className='btn btn-light-primary btn-sm me-2'
                          onClick={() => setShowVidModal(true)}
                        >
                          Select Video
                        </button>
                        {values.media_id && (
                          <span className='fw-semibold'>
                            Selected:{' '}
                            <span className='fw-normal'>
                              {values.media_title_en || values.media_title_ar}
                            </span>
                          </span>
                        )}
                      </div>
                      <ErrorMessage name='media_id' component='div' className='text-danger mt-2' />
                    </div>
                  )}

                  {values.media_type === 'G' && (
                    <div className='w-100 mb-5'>
                      <label className='fs-6 fw-semibold form-label mb-2 required'>
                        Upload Gallery
                      </label>
                      <div className='d-flex align-items-center'>
                        {values.media_url ? (
                          <div className='w-100 w-md-50' onClick={() => setShowGalleryModal(true)}>
                            <Carousel>
                              {JSON.parse(values.media_url)?.map((image: string, index: number) => (
                                <Carousel.Item key={`Slide ${index}`}>
                                  <img
                                    className='d-block w-100 h-100px rounded'
                                    src={image}
                                    alt={'Slider/Gallery'}
                                  />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div>
                        ) : (
                          <div
                            className='border border-3 border-secondary border-dashed d-flex align-items-center justify-content-center me-5 cursor-pointer rounded'
                            style={{width: '80px', height: '80px'}}
                            onClick={() => setShowGalleryModal(true)}
                          >
                            <KTIcon iconName='user' className='fs-2' />
                          </div>
                        )}
                        <button
                          type='button'
                          onClick={() => setShowGalleryModal(true)}
                          className='btn btn-light-primary btn-sm ms-4'
                        >
                          Select Gallery
                        </button>
                      </div>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='media_id' />
                      </div>
                    </div>
                  )}

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Section</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select the section of the website or publication where this article will appear.'
                      ></i>
                    </label>
                    <Field
                      as='select'
                      name='section_id'
                      className='form-select form-select-solid'
                      value={secId}
                      disabled={sections?.length}
                      onChange={(e: any) => {
                        setFieldValue('section_id', e.target.value)
                        setFieldValue('category_id', null)
                        setFieldValue('sub_category_id', null)
                        setCategories([])
                        setSubCategories([])
                        setSelectedCategoryId(null)
                        setSelectedSectionId(e.target.value)
                      }}
                    >
                      <option value={''}>Select Section</option>
                      {sections.map((each: any) => (
                        <option key={each.section_id} value={each.section_id}>
                          {each.section_name_ar} - {each.section_name_en}
                        </option>
                      ))}
                    </Field>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='section_id' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Category</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose a category that best fits the content of your article.'
                      ></i>
                    </label>
                    <div className='d-flex align-items-center'>
                      <div style={{width: '95%'}}>
                        <Select
                          value={categories.find(
                            (option: any) => option.value === values.category_id
                          )}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles, state) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles, {isFocused, isSelected}) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container me-1'
                          classNamePrefix='react-select'
                          options={categories}
                          isSearchable
                          placeholder='Select Category'
                          onChange={(val) => handleCategoryChange(setFieldValue, val)}
                        />
                      </div>
                      <button
                        type='button'
                        className='btn btn-icon btn-light btn-sm ms-1'
                        data-bs-toggle='tooltip'
                        onClick={() => setShowAddCategoryModal(true)}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='category_id' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span>Subcategory</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select a subcategory to further refine the topic of your article.'
                      ></i>
                    </label>
                    <div className='d-flex align-items-center'>
                      <div style={{width: '95%'}}>
                        <Select
                          value={subCategories?.find(
                            (option: any) => option.value === values.sub_category_id
                          )}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles, state) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles, {isFocused, isSelected}) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container me-1'
                          classNamePrefix='react-select'
                          options={subCategories}
                          isSearchable
                          placeholder='Select Subcategory'
                          onChange={(val) => handlesubcategoryChange(setFieldValue, val)}
                        />
                      </div>
                      <button
                        type='button'
                        className='btn btn-icon btn-light btn-sm ms-1'
                        data-bs-toggle='tooltip'
                        onClick={() => {
                          if (!selectedCategoryId) {
                            alert(
                              'Category not selected. Please select a category to create a subcategory.'
                            )
                          } else {
                            setShowAddSubCategoryModal(true)
                          }
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='sub_category_id' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Created Date</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select the date when this article was created.'
                      ></i>
                    </label>
                    <Field name='start_ts'>
                      {({form, field}: any) => {
                        const {setFieldValue} = form
                        const {value} = field

                        const handleChange = (date: any) => {
                          setFieldValue('start_ts', date) // Set the date directly
                          // updateData('start_ts', date) // Use the correct key to update data
                        }

                        return (
                          <div
                            className={`react-datepicker-fullwidth-wrapper test ${
                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                            }`}
                          >
                            <DatePicker
                              selected={value && new Date(value)}
                              minDate={new Date()}
                              showYearDropdown
                              scrollableYearDropdown
                              showMonthDropdown
                              useShortMonthInDropdown
                              showTimeSelect
                              timeFormat='HH:mm'
                              timeIntervals={15}
                              dateFormat='dd/MM/yyyy h:mm aa'
                              onChange={handleChange}
                              placeholderText='Select Date and Time'
                              className='form-control form-control-solid'
                              popperPlacement='bottom'
                              locale={'en'}
                            />
                          </div>
                        )
                      }}
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='start_ts' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span>End Date</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Specify the end date or expiration date for this article if applicable.'
                      ></i>
                    </label>
                    <div className='mb-4'>
                      <div className='text-start' style={{position: 'relative'}}>
                        <Field name='end_ts'>
                          {({form, field}: any) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date: any) => {
                              setFieldValue('end_ts', date) // Set the date directly
                            }
                            const currentDate = new Date()
                            const tomorrow = new Date(currentDate.setDate(new Date().getDate() + 1)) // Today's date + 1
                            const createdDate = new Date(form.values.start_ts) // Convert start_ts to a Date object

                            let minDate = null

                            if (values && values.start_ts) {
                              const ruleStartDate = new Date(values.start_ts)
                              minDate = ruleStartDate.setDate(ruleStartDate.getDate() + 1)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  minDate={minDate || tomorrow}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  showTimeSelect
                                  timeFormat='HH:mm'
                                  timeIntervals={15}
                                  dateFormat='dd/MM/yyyy h:mm aa'
                                  onChange={handleChange}
                                  placeholderText='Select Date and Time'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                  locale={'en'}
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <span
                          className={`cursor-pointer ${values.end_ts ? '' : 'd-none'}`}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: '0',
                            transform: 'translateY(-50%)',
                          }}
                          onClick={() => setFieldValue('end_ts', '')}
                        >
                          <KTIcon iconName='cross' className='fs-1 me-2 mt-1' />
                        </span>
                      </div>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='end_ts' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Voice Type (Arabic)</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose how the Arabic content will be voiced: Text-to-Speech (TTS) or Recorded (REC).'
                      ></i>
                    </label>

                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='genderMale'
                        name='voice_ar_type'
                        value='TTS'
                        checked={values.voice_ar_type === 'TTS'} // Check if the value is 'M' (Male)
                        onChange={(e: any) => {
                          // updateData(e.target.name, e.target.value)
                          setFieldValue('voice_ar_type', e.target.value)
                        }} // // Handle change and update Formik state
                      />
                      <label className='form-check-label fs-6' htmlFor='genderMale'>
                        TTS
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='genderFemale'
                        name='voice_ar_type'
                        value='REC'
                        checked={values.voice_ar_type === 'REC'} // Check if the value is 'F' (Female)
                        onChange={(e: any) => {
                          // updateData(e.target.name, e.target.value)
                          setFieldValue('voice_ar_type', e.target.value)
                        }} // // Handle change and update Formik state
                      />
                      <label className='form-check-label fs-6' htmlFor='genderFemale'>
                        REC
                      </label>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='voice_ar_type' />
                    </div>
                  </div>

                  <div
                    className={`mb-5 ${
                      values.voice_ar_type === 'REC' && values.voice_en_type !== 'REC'
                        ? 'w-100'
                        : values.voice_ar_type !== 'REC'
                        ? 'd-none'
                        : 'w-100'
                    }`}
                  >
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Upload Arabic Recording</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title="Upload the Arabic audio recording after selecting 'REC' as the voice type."
                      ></i>
                    </label>
                    <div className='d-flex align-items-center'>
                      <button
                        className='btn btn-light-primary btn-sm'
                        onClick={() => {
                          setShowAudioSelectModal(true)
                        }}
                        type='button'
                      >
                        <i className='las la-cloud-upload-alt fs-2'></i>Select from uploads
                      </button>
                    </div>

                    {selectedAudio &&
                      (selectedAudio.media_title_en || selectedAudio.media_title_ar) && (
                        <span className='fw-semibold'>
                          Selected:{' '}
                          <span className='fw-normal'>
                            {selectedAudio?.media_title_en || selectedAudio?.media_title_ar}
                          </span>
                        </span>
                      )}
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='voice_ar' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Voice Type (English)</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose how the English content will be voiced: Text-to-Speech (TTS) or Recorded (REC).'
                      ></i>
                    </label>

                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='genderMale'
                        name='voice_en_type'
                        value='TTS'
                        checked={values.voice_en_type === 'TTS'} // Check if the value is 'M' (Male)
                        onChange={(e: any) => {
                          // updateData(e.target.name, e.target.value)
                          setFieldValue('voice_en_type', e.target.value)
                        }} // Handle change and update Formik state
                      />
                      <label className='form-check-label fs-6' htmlFor='genderMale'>
                        TTS
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='genderFemale'
                        name='voice_en_type'
                        value='REC'
                        checked={values.voice_en_type === 'REC'} // Check if the value is 'F' (Female)
                        onChange={(e: any) => {
                          // updateData(e.target.name, e.target.value)
                          setFieldValue('voice_en_type', e.target.value)
                        }} // Handle change and update Formik state
                      />
                      <label className='form-check-label fs-6' htmlFor='genderFemale'>
                        REC
                      </label>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='voice_en_type' />
                    </div>
                  </div>

                  <div className={`mb-5 ${values.voice_en_type !== 'REC' ? 'd-none' : ''}`}>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Upload English Recording</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title="Upload the English audio recording after selecting 'REC' as the voice type."
                      ></i>
                    </label>
                    <div className='d-flex align-items-center'>
                      <button
                        className='btn btn-light-primary btn-sm'
                        onClick={() => {
                          setShowAudioSelectModalEn(true)
                        }}
                        type='button'
                      >
                        <i className='las la-cloud-upload-alt fs-2'></i>Select from uploads
                      </button>
                    </div>
                    {selectedAudioEn &&
                      (selectedAudioEn.media_title_en || selectedAudioEn.media_title_ar) && (
                        <span className='fw-semibold'>
                          Selected:{' '}
                          <span className='fw-normal'>
                            {selectedAudioEn?.media_title_en || selectedAudioEn?.media_title_ar}
                          </span>
                        </span>
                      )}
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='voice_en' />
                    </div>
                  </div>

                  <div className='w-100 me-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Sort Rank</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter the title of the article in Arabic.'
                      ></i>
                    </label>
                    <Field
                      type='number'
                      name='sort_rank'
                      className='form-control form-control-solid'
                      placeholder='Enter sort rank'
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      //   const {value} = e.target
                      //   setFieldValue('title_en', value)

                      //   // Update title_en_phonetic only if it's empty or hasn't been manually changed
                      //   if (
                      //     !values.title_en_phonetic ||
                      //     values.title_en_phonetic === values.title_en
                      //   ) {
                      //     setFieldValue('title_en_phonetic', value)
                      //   }
                      // }}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='sort_rank' />
                    </div>
                  </div>

                  <div className='mt-3'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Status</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose the current status of the article (Active, Inactive).'
                      ></i>
                    </label>

                    <div className='form-floating flex-fill'>
                      <div className='form-check form-check-solid form-switch mb-0'>
                        <Field
                          className='form-check-input w-40px h-20px'
                          type='checkbox'
                          name='status'
                          id='toggle'
                          style={{
                            backgroundColor: values.status
                              ? '#009688'
                              : mode === 'dark'
                              ? '#1b1b29'
                              : '#f4f4f4',
                          }}
                        />
                        <span
                          className={`me-4 fw-semibold ${
                            values.status ? 'text-success' : 'text-danger'
                          }`}
                        >
                          {values.status ? 'Active' : 'Inactive'}
                        </span>
                        <ErrorMessage component='div' className='text-danger' name='status' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const ImageSelectModal = ({
  showImgSelectModal,
  setShowImgSelectModal,
  handleImageSelect,
  setFieldValue,
}: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const [inputVal, setInputVal] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 18

  const [imagesData, setImagesData] = useState([])

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'I',
      }
      const response = await execute(url, 'POST', formData)
      setImagesData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])

  const filteredImages = useMemo(() => {
    const matches = (field: string) =>
      field?.toLowerCase().includes(inputVal.toLowerCase()) || false

    return imagesData
      ? imagesData.filter((each: any) => {
          const titleAr = matches(each.media_title_ar)
          const titleEn = matches(each.media_title_en)
          const descAr = matches(each.media_desc_ar)
          const descEn = matches(each.media_desc_en)

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, imagesData])

  const totalPages = Math.ceil(filteredImages?.length / imagesPerPage) // Moved inside render function

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showAddImgModal, setShowAddImgModal] = useState(false)

  return (
    <Modal
      show={showImgSelectModal}
      onHide={() => setShowImgSelectModal(false)}
      centered
      dialogClassName='custom-modal-img-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <AddNewAttachment
          showModal={showAddImgModal}
          setShowModal={setShowAddImgModal}
          type='add'
          data={{}}
          loadData={getMediatype}
          setMediaId={(img: any) => {
            setFieldValue('media_id', img.media_id)
            setFieldValue('media_url', img.media_location_url)
            setFieldValue('media_title_en', img.media_title_en)
            setFieldValue('media_title_en', img.media_title_ar)
            setShowAddImgModal(false)
            setShowImgSelectModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Image</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowImgSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search image'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            className='btn btn-light-primary btn-sm ms-2'
            onClick={() => setShowAddImgModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='d-flex flex-wrap gap-3'>
          {paginatedImages?.length > 0 ? (
            paginatedImages.map((each: any) => (
              <div
                key={each.media_id}
                onClick={() => {
                  handleImageSelect(each, setFieldValue)
                }}
              >
                <img
                  src={each.media_location_url}
                  className='w-100px h-100px rounded'
                  alt={each.media_title_en || each.media_title_ar}
                />
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center w-100'>
              {inputVal === '' ? (
                intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
              ) : (
                <FormattedMessage
                  id='MSG.NO_DATA_MATCH'
                  values={{
                    inputVal: <span className='text-primary'>{inputVal}</span>,
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const AudioSelectModal = ({
  showAudioSelectModal,
  setShowAudioSelectModal,
  handleAudioSelect,
  setFieldValue,
  audiosData,
  getMediatype: mediafetchData,
}: any) => {
  const [inputVal, setInputVal] = useState('')

  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 6

  const filteredImages = useMemo(() => {
    const matches = (field: string) =>
      field?.toLowerCase().includes(inputVal.toLowerCase()) || false

    return audiosData
      ? audiosData.filter((each: any) => {
          const titleAr = matches(each.media_title_ar)
          const titleEn = matches(each.media_title_en)
          const descAr = matches(each.media_desc_ar)
          const descEn = matches(each.media_desc_en)

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, audiosData])

  const totalPages = Math.ceil(filteredImages?.length / imagesPerPage)

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showAddModal, setShowAddModal] = useState(false)

  return (
    <Modal
      show={showAudioSelectModal}
      onHide={() => setShowAudioSelectModal(false)}
      centered
      dialogClassName='custom-modal-audio-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <UploadAudio
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={mediafetchData}
          setMediaId={(audio: any) => {
            handleAudioSelect(audio, setFieldValue)
            setShowAddModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Audio</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowAudioSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search audio'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-light-primary ms-2'
            onClick={() => setShowAddModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='d-flex flex-wrap'>
          {paginatedImages?.length > 0 ? (
            paginatedImages.map((each: any, index: number) => (
              <div
                key={index}
                className='w-100 w-lg-50 d-flex align-items-center mb-5'
                onClick={() => {
                  handleAudioSelect(each, setFieldValue)
                }}
              >
                <img src={AudioIcon} alt='' className='w-75px w-lg-90px' />
                <div className='d-flex flex-column'>
                  <audio src={each.media_location_url} controls />
                  <span className='fs-6'>{each?.media_title_en || each?.media_title_ar}</span>
                </div>
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center w-100'>
              {inputVal === '' ? (
                intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
              ) : (
                <FormattedMessage
                  id='MSG.NO_DATA_MATCH'
                  values={{
                    inputVal: <span className='text-primary'>{inputVal}</span>,
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const VideoSelectModal = ({
  showVidSelectModal,
  setShowVidSelectModal,
  handleVidSelect,
  setFieldValue,
}: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const [inputVal, setInputVal] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 8

  const [videosData, setVideosData]: any = useState([])

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'V',
      }
      const response = await execute(url, 'POST', formData)
      setVideosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])

  const filteredImages = useMemo(() => {
    const matches = (field: string) =>
      field?.toLowerCase().includes(inputVal.toLowerCase()) || false

    return videosData
      ? videosData.filter((each: any) => {
          const titleAr = matches(each.media_title_ar)
          const titleEn = matches(each.media_title_en)
          const descAr = matches(each.media_desc_ar)
          const descEn = matches(each.media_desc_en)

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, videosData])

  const totalPages = Math.ceil(filteredImages?.length / imagesPerPage) // Moved inside render function

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showModal, setShowModal] = useState(false)
  const [selectedVideo, setSelectedVideo]: any = useState(null)

  const handleSelectVideo = (mediaItem: any) => {
    setSelectedVideo(mediaItem)
    setShowModal(true) // Show the modal when a video is selected
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedVideo(null)
  }
  const getYouTubeVideoId = (url: string) => {
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
    )
    return videoIdMatch ? videoIdMatch[1] : null
  }

  const [showAddVidModal, setShowAddVidModal] = useState(false)

  return (
    <Modal
      show={showVidSelectModal}
      onHide={() => setShowVidSelectModal(false)}
      centered
      dialogClassName='custom-modal-audio-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <UploadVideo
          showModal={showAddVidModal}
          setShowModal={setShowAddVidModal}
          type='add'
          data={{}}
          loadData={getMediatype}
          setMediaId={(vid: any) => {
            setFieldValue('media_id', vid.media_id)
            setFieldValue('media_url', vid.media_location_url)
            setFieldValue('media_title_en', vid.media_title_en)
            setFieldValue('media_title_en', vid.media_title_ar)
            setShowAddVidModal(false)
            setShowVidSelectModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Video</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowVidSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search video'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            className='btn btn-light-primary btn-sm ms-2'
            onClick={() => setShowAddVidModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='container'>
          <div className='row mb-0'>
            {paginatedImages && paginatedImages?.length > 0 ? (
              <>
                {paginatedImages?.map((mediaItem: any) => {
                  const videoId = getYouTubeVideoId(mediaItem?.media_location_url)
                  return (
                    <div
                      key={mediaItem.media_id}
                      className={`col-6 col-md-4 col-lg-3 mb-4${
                        selectedVideo?.media_id === mediaItem.media_id
                          ? ' border border-primary'
                          : ''
                      }`}
                      style={{cursor: 'pointer', height: 'auto'}}
                    >
                      <div className='d-flex flex-column h-100'>
                        <img
                          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                          alt={mediaItem.media_title_en}
                          className='w-100 rounded shadow-sm mb-2'
                          onClick={() => handleVidSelect(mediaItem, setFieldValue)}
                          style={{objectFit: 'cover', height: '150px'}}
                        />
                        <div className='d-flex justify-content-between align-items-center'>
                          <span className='fs-7 text-truncate' style={{maxWidth: '70%'}}>
                            {mediaItem.media_title_en}
                          </span>
                          <span
                            className='text-primary ms-2 cursor-pointer text-nowrap'
                            onClick={() => handleSelectVideo(mediaItem)}
                          >
                            View
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className='d-flex justify-content-center w-100'>
                {inputVal === '' ? (
                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                ) : (
                  <FormattedMessage
                    id='MSG.NO_DATA_MATCH'
                    values={{
                      inputVal: <span className='text-primary'>{inputVal}</span>,
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {selectedVideo && (
            <VideoModal
              show={showModal}
              onHide={handleCloseModal}
              videoUrl={selectedVideo.media_location_url}
              title={selectedVideo.media_title_en}
            />
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const VideoModal = ({show, onHide, videoUrl, title}: any) => {
  const getVideoType = (url: string) => {
    if (!url) return
    // Check if the URL is a YouTube link (both short and long formats)
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
      const videoId = url?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
      )
      return videoId ? {type: 'youtube', id: videoId[1]} : null
    }

    // Check if the URL is an mp4 link
    if (url?.endsWith('.mp4')) {
      return {type: 'mp4', url}
    }

    return null
  }

  let video: any = getVideoType(videoUrl)
  useEffect(() => {
    if (videoUrl) {
      video = getVideoType(videoUrl)
    }
  }, [videoUrl])

  return (
    <Modal show={show} onHide={onHide} centered size='lg'>
      <div className='d-flex justify-content-between mx-7 my-5'>
        <h3 className='fs-3 fw-semibold'>{title}</h3>
        <button type='button' className='d-block btn btn-sm btn-icon btn-light' onClick={onHide}>
          <KTIcon iconName='cross' className='fs-1' />
        </button>
      </div>
      <Modal.Body className='h-500px mt-0'>
        {video ? (
          video.type === 'youtube' ? (
            // YouTube video
            <iframe
              // width='660'
              // height='415'
              className='w-100 h-100'
              src={`https://www.youtube.com/embed/${video.id}`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          ) : (
            // MP4 video
            <video className='w-100' height='415' controls>
              <source src={video.url} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          )
        ) : (
          <p>Invalid video URL</p>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default CreateArticleFullPage

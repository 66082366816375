export const formatDateTime = (dateString: string, type: 'date' | 'datetime' = 'date'): string => {
  if (!dateString) {
    console.warn('Invalid date string')
    return ''
  }

  const dateObj = new Date(dateString)

  if (isNaN(dateObj.getTime())) {
    console.warn('Invalid date string format')
    return '--'
  }

  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = dateObj.getFullYear()
  const hours = String(dateObj.getHours()).padStart(2, '0')
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const seconds = String(dateObj.getSeconds()).padStart(2, '0')

  if (type === 'datetime') {
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}` // DD-MM-YYYY HH:mm:ss
  }

  return `${day}-${month}-${year}` // DD-MM-YYYY
}

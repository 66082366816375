import React, {useEffect} from 'react'
import {FC, useState, useRef} from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {useAuth} from '../core/Auth'
import './index.css'
import {KTIcon} from '../../../../_metronic/helpers'
import {motion, AnimatePresence} from 'framer-motion'
import usePostApi from '../../custom_hooks/usePostApi'
import ReCAPTCHA from 'react-google-recaptcha'
import SecureLocalStorage from '../../custom_hooks/SecureLocalStorage'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const loginSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegex, 'Invalid email format').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  not_robot: Yup.string().required('Please verify that you are not a robot.'),
})

const initialValues = {
  email: '',
  password: '',
  not_robot: '',
}

const Login: FC = () => {
  const {execute, postLoading} = usePostApi()
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginError, setLoginError] = useState(null)

  const {saveAuth, setCurrentUser} = useAuth()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [ip, setIp] = useState('')
  const scrambleX = (str: string, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const fetchIp = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json')
      const data = await response.json()
      setIp(data.ip)
    } catch (error) {
      console.error('Error fetching IP address:', error)
      setIp('')
    }
  }
  useEffect(() => {
    fetchIp()
  }, [])

  const showPassword = () => {
    setPasswordVisible(!passwordVisible)
  }

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [recaptchaSelected, setRecaptchaSelected] = useState(false)

  const onSubmit = async (values: any) => {
    try {
      const url = `/auth/login`
      const formData = {
        email: values.email,
        password: scrambleX(values.password),
        deviceId: ip,
        recaptcha_validation_token: values.not_robot,
      }

      const response = await execute(url, 'POST', formData, 'post')
      const userInfo = {
        auth_token: response.data.token,
        user_id: response.data.admin_id,
      }
      SecureLocalStorage.setItem('user_info', userInfo)

      saveAuth(response)
      setCurrentUser(response.data)
    } catch (error: any) {
      if (error.response) {
        setLoginFailed(error.response.data.status === false)
        setLoginError(error.response.data.status_message)
      }

      // console.error(error)
      saveAuth(undefined)
      // setStatus('The login details are incorrect')
    }
  }

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{opacity: 0, y: -100}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.5}}
        className=''
        // className='max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl'
      >
        <div className='login-light-mode'>
          <Formik validationSchema={loginSchema} initialValues={initialValues} onSubmit={onSubmit}>
            {({setFieldValue, values}) => {
              return (
                <Form className='min-h-100 w-100 min-w-lg-350px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-2'>
                  <div className='text-center mb-11'>
                    <h1 className='fw-semibold mb-3' style={{color: '#181c32'}}>
                      Sign In
                    </h1>
                  </div>

                  <div className='fv-row mb-8'>
                    <p className='mb-0 form-label fs-6 fw-semibold' style={{color: '#181c32'}}>
                      {' '}
                      Email
                    </p>
                    <Field
                      name='email'
                      className='form-control'
                      placeholder='Email'
                      autoFocus
                      onChange={(e: any) => {
                        setFieldValue('email', e.target.value)
                        if (recaptchaSelected && recaptchaRef.current) {
                          recaptchaRef.current.reset()
                          setFieldValue('not_robot', '')
                          setRecaptchaSelected(false)
                        }
                      }}
                    />
                    <ErrorMessage name='email' component='div' className='text-danger' />
                  </div>

                  <div className='fv-row mb-8'>
                    <p className='mb-0 form-label fs-6 fw-semibold' style={{color: '#181c32'}}>
                      Password
                    </p>
                    <div className='position-relative'>
                      <Field
                        name='password'
                        type={passwordVisible ? 'text' : 'password'}
                        className='form-control pr-10'
                        placeholder='Password'
                        onChange={(e: any) => {
                          setFieldValue('password', e.target.value)
                          if (recaptchaSelected && recaptchaRef.current) {
                            recaptchaRef.current.reset()
                            setFieldValue('not_robot', '')
                            setRecaptchaSelected(false)
                          }
                        }}
                      />
                      <span onClick={showPassword}>
                        <KTIcon
                          iconName={passwordVisible ? 'eye-slash' : 'eye'}
                          className='fs-2 icon'
                        />
                      </span>
                    </div>
                    <ErrorMessage name='password' component='div' className='text-danger' />
                  </div>
                  {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8 d-flex justify-content-end'>
                    <Link to='/auth/forgot' className='login-text active'>
                      Forgot Password?
                    </Link>
                  </div> */}

                  <div className='mb-8 w-100 d-flex justify-content-center'>
                    <div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                        onChange={(val: string | null) => {
                          setFieldValue('not_robot', val)
                          setRecaptchaSelected(true)
                        }}
                        onExpired={() => {
                          setFieldValue('not_robot', '')
                          setRecaptchaSelected(false)
                        }}
                      />
                      <ErrorMessage name='not_robot' component='div' className='text-danger' />
                    </div>
                  </div>

                  <div className='d-grid mb-2'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn text-light login-btn'
                      disabled={postLoading}
                    >
                      {!postLoading && (
                        <span className='indicator-label' style={{color: '#fff'}}>
                          Login
                        </span>
                      )}

                      {postLoading && (
                        <span
                          className='indicator-progress'
                          style={{display: 'block', color: '#fff'}}
                        >
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    {/* {loginFailed && <label className='text-danger'>{loginError}</label>} */}
                  </div>
                  {loginFailed && (
                    <div className='alert border border-dashed border-gray-300 rounded px-5 py-3 badge-light-danger d-flex align-items-center p-2 mt-4 d-flex flex-row align-items-center'>
                      <KTIcon iconName='information' className='fs-3 text-danger me-1' />
                      <span className='fs-6' style={{color: '#181c32'}}>
                        {loginError}
                      </span>
                    </div>
                  )}
                  {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
                    Don’t have an account?{' '}
                    <Link to='/auth/registration' className='login-text active'>
                      Create Account
                    </Link>
                  </div> */}
                </Form>
              )
            }}
          </Formik>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export {Login}

import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useAuth} from '../../../auth'
import usePostApi from '../../../custom_hooks/usePostApi'
import {UseToast} from '../../../custom_hooks/useToast'
import {useThemeMode} from '../../../../../_metronic/partials'

const youtubeRegex =
  /^((?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=)?|(?:https?:\/\/)?youtu.be\/)([\w\-]{11})(?:\?\S+)?$/
function UploadVideo({
  showModal,
  setShowModal,
  type = 'add',
  data,
  loadData,
  setMediaId = () => {},
}: any) {
  const {mode} = useThemeMode()
  const {execute, postLoading} = usePostApi()
  const [isFile, setIsFile] = useState(true)
  const step1Schema = Yup.object().shape({
    title_ar: Yup.string().required('Media title in Arabic is required'),
    desc_ar: Yup.string().required('Media description in Arabic is required'),
    youtube_vid_lnk: !isFile
      ? Yup.string()
          .required('Youtube video link is required')
          .matches(youtubeRegex, 'Invalid YouTube video link')
      : Yup.string(),
    attachmentFile:
      type === 'add' && isFile === true
        ? Yup.mixed().required('Attachment file is required')
        : Yup.mixed().nullable(),
  })

  const [initialValues, setInitialVals] = useState({
    title_ar: '',
    title_en: '',
    desc_ar: '',
    desc_en: '',
    attachmentFile: null,
    tags: '',
    youtube_vid_lnk: '',
    status: true,
  })

  useEffect(() => {
    if (type === 'edit') {
      const dataObj = {
        title_ar: data?.media_title_ar || '',
        title_en: data?.media_title_en || '',
        desc_ar: data?.media_desc_ar || '',
        desc_en: data?.media_desc_en || '',
        attachmentFile: null,
        tags: '',
        youtube_vid_lnk: '',
        status: data.status == 1 ? true : false,
      }
      setInitialVals(dataObj)
    }
  }, [type, data])

  const [sizeErr, setSizeErr] = useState(false)
  const isFileSizeValid = (file: any, size: number = 8) => {
    const maxSizeInBytes = size * 1024 * 1024
    const ans = file.size <= maxSizeInBytes
    return ans
  }

  const onSubmit = async (values: any, {resetForm}: any) => {
    if (sizeErr) {
      return
    }
    let editFormData

    if (type === 'edit') {
      const metadata = JSON.parse(data.metadata)
      editFormData = {
        media_id: data?.media_id,
        status: values.status ? 1 : 0,
        meta_data: {
          ...metadata,
        },
        media_desc_ar: values.desc_ar,
        media_desc_en: values.desc_en,
        media_title_ar: values.title_ar,
        media_title_en: values.title_en,
      }
    }

    try {
      const url = type === 'add' ? '/media/video/create' : '/media/update'
      const formData = new FormData()
      if (type === 'add') {
        formData.append('media_title_ar', values.title_ar)
        formData.append('media_title_en', values.title_en)
        formData.append('media_desc_ar', values.desc_ar)
        formData.append('media_desc_en', values.desc_en)
        formData.append('meta_data', '')
        formData.append('media_type', 'V')
        formData.append('status', values.status ? '1' : '0')
        if (isFile) {
          formData.append('uploaded_file', values.attachmentFile)
        } else {
          formData.append('link', isFile ? null : values.youtube_vid_lnk)
        }
      }

      const response = await execute(url, 'POST', type === 'edit' ? editFormData : formData, 'post')
      setMediaId(response.data)
      loadData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'success'
      )
      resetForm()
      setIsFile(true)
      setShowModal(false)
    } catch (error: any) {
      console.error('Error uploading document:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'error'
      )
      throw error
    }
  }
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      style={{backdropFilter: 'brightness(90%)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h3 className='fs-3 fw-semibold'>{type === 'edit' ? 'Edit' : 'Upload'} Video</h3>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => setShowModal(false)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>

            <Formik
              validationSchema={step1Schema}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({setFieldValue, values}) => {
                return (
                  <Form className='w-100'>
                    <div className='d-flex flex-column flex-md-row justify-content-between'>
                      <div className='w-100 w-md-50 me-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Title (Arabic)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment title in Arabic.'
                          ></i>
                        </label>
                        <Field
                          id='title_ar'
                          type='text'
                          name='title_ar'
                          className='form-control form-control-solid'
                          placeholder='أدخل عنوان المرفق'
                          dir='rtl'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='title_ar' />
                        </div>
                      </div>
                      <div className='w-100 w-md-50 ms-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span>Title (English)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment title in English.'
                          ></i>
                        </label>
                        <Field
                          id='title_en'
                          type='text'
                          name='title_en'
                          className='form-control form-control-solid'
                          placeholder='Enter attachment title'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='title_en' />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                      <div className='w-100 w-md-50 me-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Description (Arabic)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment description in Arabic.'
                          ></i>
                        </label>
                        <Field
                          as='textarea'
                          id='desc_ar'
                          type='text'
                          name='desc_ar'
                          className='form-control form-control-solid'
                          placeholder='أدخل وصف المرفق'
                          dir='rtl'
                          rows={3}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='desc_ar' />
                        </div>
                      </div>
                      <div className='w-100 w-md-50 ms-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span>Description (English)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment description in English.'
                          ></i>
                        </label>
                        <Field
                          as='textarea'
                          id='desc_en'
                          type='text'
                          name='desc_en'
                          className='form-control form-control-solid'
                          placeholder='Enter attachment description'
                          rows={3}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='desc_en' />
                        </div>
                      </div>
                    </div>

                    <div className='w-100 d-flex flex-column flex-md-row mt-3'>
                      {type === 'add' && (
                        <div className='w-100 w-md-50 me-md-2'>
                          <div className='d-flex justify-content-between'>
                            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                              <span className='required'>Attachment</span>
                              <i
                                className='fas fa-info-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Please upload the attachment file.'
                              ></i>
                            </label>
                            <p
                              className='text-primary cursor-pointer'
                              onClick={() => setIsFile(!isFile)}
                            >
                              {isFile ? 'I have a youtube link' : "I don't have a link"}
                            </p>
                          </div>
                          {isFile ? (
                            <>
                              <input
                                name='attachmentFile'
                                type='file'
                                accept='video/*'
                                className='form-control form-control-solid'
                                onChange={(event: any) => {
                                  const file = event.currentTarget.files[0]
                                  const checkSize = isFileSizeValid(file)
                                  if (!checkSize) {
                                    setSizeErr(true)
                                    return
                                  } else {
                                    setSizeErr(false)
                                  }
                                  setFieldValue('attachmentFile', event.currentTarget.files[0])

                                  const fileNameWithoutExtension = file.name
                                    .split('.')
                                    .slice(0, -1)
                                    .join('.')
                                  setFieldValue('attachmentFile', file)
                                  if (!values.title_ar) {
                                    setFieldValue('title_ar', fileNameWithoutExtension)
                                  }
                                  if (!values.title_en) {
                                    setFieldValue('title_en', fileNameWithoutExtension)
                                  }
                                  if (!values.desc_ar) {
                                    setFieldValue('desc_ar', fileNameWithoutExtension)
                                  }
                                  if (!values.desc_en) {
                                    setFieldValue('desc_en', fileNameWithoutExtension)
                                  }
                                  setSizeErr(false)
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Field
                                id='youtube_vid_lnk'
                                type='text'
                                name='youtube_vid_lnk'
                                className='form-control form-control-solid'
                                placeholder='Enter video link'
                              />
                            </>
                          )}
                          <ErrorMessage
                            name={`${isFile ? 'attachmentFile' : 'youtube_vid_lnk'}`}
                            component='div'
                            className='text-danger mb-2'
                          />
                          {sizeErr && <p className='text-danger'>Maximum allowed size is 8mb</p>}
                        </div>
                      )}
                      <div className={`mt-3 w-100 w-md-50 ${type === 'add' ? 'ms-2' : ''}`}>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className=''>Status</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Choose the current status of the policy (Active, Inactive).'
                          ></i>
                        </label>

                        <div className='form-floating flex-fill'>
                          <div className='form-check form-check-solid form-switch mb-0'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              name='status'
                              id='toggle'
                              style={{
                                backgroundColor: values.status
                                  ? '#009688'
                                  : mode === 'dark'
                                  ? '#1b1b29'
                                  : '#f4f4f4',
                              }}
                            />
                            <span
                              className={`me-4 fw-semibold ${
                                values.status ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.status ? 'Active' : 'Inactive'}
                            </span>
                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='w-100 d-flex justify-content-end mt-5'>
                      <div>
                        <button
                          type='button'
                          className='btn btn-sm btn-light me-2'
                          onClick={() => setShowModal(false)}
                        >
                          <KTIcon iconName='cross' className='fs-3' />
                          Close
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary ms-2'
                          disabled={postLoading}
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              <KTIcon iconName='check' className='fs-3' />
                              {type === 'edit' ? 'Save Changes' : 'Add'}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UploadVideo

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='min-h-100 d-flex flex-column justify-content-center flex-lg-row-fluid w-lg-50 p-0 p-md-10 order-1 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-0 p-md-10'>
            <Outlet />
          </div>
        </div>
      </div>
      <div
        className='min-h-100 d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/custom_assets/logo_minimalistic_full_light.svg')}
            className='h-50px h-md-75px app-sidebar-logo-default'
            style={{maxWidth: '100%', objectFit: 'contain'}}
          />
          <h1 className='fw-normal mt-5' style={{color: '#f5f5f5'}}>
            Catalyzing your tomorrow, today!
          </h1>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}

import React, {FC, useState, useEffect} from 'react'
import usePostApi from '../../modules/custom_hooks/usePostApi'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useThemeMode} from '../../../_metronic/partials'
import usePageTitle from '../../modules/custom_hooks/usePageTitle'

const DashboardPage: FC = () => {
  const {mode} = useThemeMode()
  const {execute, loading} = usePostApi()

  const [tLoad, setTLoad] = useState(false)

  usePageTitle('Quick Navigation')

  const tokenValidate = async () => {
    try {
      const url = `/get/tkn-vlidation-result/`
      const formData = {}
      const response = await execute(url, 'POST', formData)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [data, setData] = useState([])

  const fetchData = () => {
    setTLoad(true)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            id: 1,
            title: 'Communities',
            to: '/1/communities/manage-topics',
            items: ['Manage Topics', 'Category & Sub-category'],
          },
          {
            id: 2,
            title: 'Proverbs',
            to: '/2/proverbs/manage-proverbs',
            items: ['Manage Proverbs', 'Category & Sub-category'],
          },
          {
            id: 3,
            title: 'Jobs',
            to: '/3/jobs/manage-jobs',
            items: ['Manage Jobs', 'Category & Sub-category'],
          },
          {id: 4, title: 'Dictionary', to: '/dictionary/words-definitions', items: []},
          {id: 5, title: 'Locations', to: '/locations', items: []},
          {id: 6, title: 'Events', to: '/events', items: []},
          {
            id: 7,
            title: 'Podcasts',
            to: '/4/podcasts/seasons',
            items: ['Episodes', 'Category & Sub-category'],
          },
          {id: 8, title: 'Quiz', to: '/quiz/configure-quizzes', items: []},
          {id: 9, title: 'Media', to: '/media', items: []},
          {id: 10, title: "Ruler's Message", to: '/rulers-message', items: []},
          {id: 11, title: 'Awards', to: '/awards', items: []},
          {
            id: 12,
            title: 'Feedback',
            to: '/feedback/support-form',
            items: ['Support Form', 'Happiness Feedback'],
          },
          {id: 13, title: 'Company Pages', to: '/cms-pages', items: []},
          {
            id: 14,
            title: 'Administration',
            to: '/administration/user-submissions',
            items: [
              'User Submissions',
              'Notifications',
              'App Versions',
              'End Users',
              'Admin Users',
            ],
          },
        ])
        setTLoad(false)
      }, 1000)
    })
  }

  useEffect(() => {
    tokenValidate()
    fetchData().then((response: any) => {
      setData(response)
    })
  }, [])

  if (tLoad) {
    return (
      <div className='card h-250px'>
        <div className='m-auto d-flex flex-column align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      </div>
    )
  }

  return (
    <>
      <h3 className='fw-semibold mb-5'>Quick Page Access</h3>

      <div className='row g-5 g-xl-5'>
        {(data || []).map((each: any) => {
          return (
            <div key={each.id} className='col-xl-3'>
              <Link to={`${each.to}`} className={`card card-xl-stretch mb-xl-6`}>
                <div className='card-body d-flex justify-content-between align-items-center py-3'>
                  <div className='text-muted'>
                    <p className='card-title fw-semibold fs-4 m-0 text-dark'>{each.title}</p>
                    <ul className='p-0 ps-5'>
                      {each?.items?.map((item: string, index: number) => (
                        <li key={`${each.id}-${index}`}>{item}</li>
                      ))}
                    </ul>
                  </div>
                  <span className='shadowrounded-circle d-flex flex-column justiify-content-center align-items-center p-1'>
                    <KTIcon iconName='arrow-right' className='fs-1 text-primary' />
                  </span>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default DashboardPage

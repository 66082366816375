import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import usePostApi from '../../../custom_hooks/usePostApi'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'

function UploadGallery({showModal, setShowModal, type = 'add', data, loadData, setMediaId}: any) {
  const {mode} = useThemeMode()
  const {execute, postLoading} = usePostApi()
  const step1Schema = Yup.object().shape({
    title_ar: Yup.string().required('Media title in Arabic is required'),
    desc_ar: Yup.string().required('Media description in Arabic is required'),
    selectedImgs: Yup.array()
      .min(1, 'You must select at least one image')
      .required('At least one image is required'),
  })
  const [initialValues, setInitialVals] = useState({
    title_ar: '',
    title_en: '',
    desc_ar: '',
    desc_en: '',
    tags: '',
    selectedImgs: [],
    status: true,
  })

  const handleImageClick = (
    id: number,
    url: string,
    setFieldValue: Function,
    selectedImgs: string[]
  ) => {
    const isImageSelected = selectedImgs.some((img) => img === url)

    if (isImageSelected) {
      setFieldValue(
        'selectedImgs',
        selectedImgs.filter((img) => img !== url)
      )
    } else {
      setFieldValue('selectedImgs', [...selectedImgs, url])
    }
  }

  useEffect(() => {
    if (type === 'edit' && data?.metadata) {
      const metadata = JSON.parse(data.metadata)
      const dataObj = {
        title_ar: data?.media_title_ar || '',
        title_en: data?.media_title_en || '',
        desc_ar: data?.media_desc_ar || '',
        desc_en: data?.media_desc_en || '',
        tags: JSON.stringify([]),
        selectedImgs: metadata,
        status: data.status === 1,
      }
      setInitialVals(dataObj)
    }
  }, [type, data])

  const [activeMedia, setActiveMedia]: any = useState([])
  const fetchData = async () => {
    try {
      const url = `/media/get/all`
      const formData = {media_type: 'I'}
      const response = await execute(url, 'POST', formData)
      const data = response.data
      setActiveMedia(data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = async (values: any, {resetForm}: {resetForm: () => void}) => {
    let editFormData

    if (type === 'edit') {
      editFormData = {
        media_id: data?.media_id,
        status: values.status ? 1 : 0,
        meta_data: values.selectedImgs,
        media_desc_ar: values.desc_ar,
        media_desc_en: values.desc_en,
        media_title_ar: values.title_ar,
        media_title_en: values.title_en,
        media_type: 'G',
      }
    }

    try {
      const url = type === 'add' ? '/media/gallery/create' : '/media/gallery/update'
      let formData
      if (type === 'add') {
        formData = {
          status: values.status ? 1 : 0,
          media_type: 'G',
          meta_data: values.selectedImgs,
          media_desc_ar: values.desc_ar,
          media_desc_en: values.desc_en,
          media_title_ar: values.title_ar,
          media_title_en: values.title_en,
        }
      }

      const response = await execute(url, 'POST', type === 'edit' ? editFormData : formData, 'post')
      setMediaId(response.data)
      setShowModal(false)
      loadData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'success'
      )
      resetForm()
    } catch (error: any) {
      console.error('Error uploading document:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'error'
      )
      throw error // Re-throw the error to handle it in the calling function
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h3 className='fs-3 fw-semibold'>{type === 'edit' ? 'Edit' : 'Upload'} Gallery</h3>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => setShowModal(false)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>

            <Formik
              validationSchema={step1Schema}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({setFieldValue, values}: any) => {
                return (
                  <Form className='w-100'>
                    <div className='d-flex flex-column flex-md-row justify-content-between'>
                      <div className='w-100 w-md-50 me-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Title (Arabic)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment title in Arabic.'
                          ></i>
                        </label>
                        <Field
                          id='title_ar'
                          type='text'
                          name='title_ar'
                          className='form-control form-control-solid'
                          placeholder='أدخل عنوان المرفق'
                          dir='rtl'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='title_ar' />
                        </div>
                      </div>
                      <div className='w-100 w-md-50 ms-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span>Title (English)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment title in English.'
                          ></i>
                        </label>
                        <Field
                          id='title_en'
                          type='text'
                          name='title_en'
                          className='form-control form-control-solid'
                          placeholder='Enter attachment title'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='title_en' />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                      <div className='w-100 w-md-50 me-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Description (Arabic)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment description in Arabic.'
                          ></i>
                        </label>
                        <Field
                          as='textarea'
                          id='desc_ar'
                          type='text'
                          name='desc_ar'
                          className='form-control form-control-solid'
                          placeholder='أدخل وصف المرفق'
                          dir='rtl'
                          rows={3}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='desc_ar' />
                        </div>
                      </div>
                      <div className='w-100 w-md-50 ms-md-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span>Description (English)</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Please enter the attachment description in English.'
                          ></i>
                        </label>
                        <Field
                          as='textarea'
                          id='desc_en'
                          type='text'
                          name='desc_en'
                          className='form-control form-control-solid'
                          placeholder='Enter attachment description'
                          rows={3}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='desc_en' />
                        </div>
                      </div>
                    </div>

                    <div className='mt-3'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className=''>Select Images</span>
                        <i
                          className='fas fa-info-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter any tag names if you want to specify.'
                        ></i>
                      </label>
                      <div
                        className='d-flex align-items-center flex-wrap overflow-auto'
                        style={{maxHeight: '250px'}}
                      >
                        {activeMedia?.map((each: any) => (
                          <div
                            key={each.media_id}
                            className='position-relative d-inline-block h-75px w-75px'
                          >
                            <img
                              key={each.media_id}
                              src={each.media_location_url}
                              alt={each.media_title_en}
                              className='w-100 h-100 p-2'
                              style={{
                                opacity: values?.selectedImgs.some(
                                  (img: string) => img === each.media_location_url
                                )
                                  ? '0.8'
                                  : '1',
                                borderRadius: '15px',
                              }}
                              onClick={() =>
                                handleImageClick(
                                  each.media_id,
                                  each.media_location_url,
                                  setFieldValue,
                                  values.selectedImgs
                                )
                              }
                            />
                            <span
                              className={`${
                                values?.selectedImgs?.some(
                                  (img: string) => img === each.media_location_url
                                )
                                  ? ''
                                  : 'd-none'
                              } position-absolute top-0 end-0 right-0`}
                              style={{cursor: 'pointer'}}
                              onClick={() =>
                                handleImageClick(
                                  each.media_id,
                                  each.media_location_url,
                                  setFieldValue,
                                  values.selectedImgs
                                )
                              }
                            >
                              <KTIcon iconName='cross-circle' className='fs-4 text-danger' />
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='selectedImgs' />
                      </div>
                    </div>

                    <div className='d-flex align-items-center mt-3'>
                      <div className=''>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className=''>Status</span>
                          <i
                            className='fas fa-info-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Choose the current status of the policy (Active, Inactive).'
                          ></i>
                        </label>

                        <div className='form-floating flex-fill'>
                          <div className='form-check form-check-solid form-switch mb-0'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              name='status'
                              id='toggle'
                              style={{
                                backgroundColor: values.status
                                  ? '#009688'
                                  : mode === 'dark'
                                  ? '#1b1b29'
                                  : '#f4f4f4',
                              }}
                            />
                            <span
                              className={`me-4 fw-semibold ${
                                values.status ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.status ? 'Active' : 'Inactive'}
                            </span>
                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='w-100 d-flex justify-content-end mt-5'>
                      <div>
                        <button
                          type='button'
                          className='btn btn-sm btn-light me-2'
                          onClick={() => setShowModal(false)}
                        >
                          <KTIcon iconName='cross' className='fs-3' />
                          Close
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary ms-2'
                          disabled={postLoading}
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              <KTIcon iconName='check' className='fs-3' />
                              {type === 'edit' ? 'Save Changes' : 'Add'}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UploadGallery
